import type { ReactElement, ReactNode } from 'react';
import type { GridRow, GridSummaryRow } from '../types';
import {
  RenderCellProps,
  RenderEditCellProps,
  SelectEditor,
  //, textEditor;
} from 'components/DataGrid';
import { createColumn } from './createColumn';
import { wrapContainer } from './style.css';
import { createOptions } from 'options';

function renderValue(props: RenderCellProps<GridRow, GridSummaryRow>): ReactNode {
  const value = props.row.kpi;
  // dash for locked
  return value ? (
    <span className={wrapContainer} title={value}>
      {value}
    </span>
  ) : props.isCellEditable ? null : (
    '—'
  );
}

const options = createOptions(['Reach', 'Awareness', 'Traffic', 'Conversions', 'Engagement']);

function Edit(props: RenderEditCellProps<GridRow, any>): ReactElement {
  return <SelectEditor options={options} {...props} isSimple isSearchable />;
}

export const KPIColumn = createColumn(
  {
    key: 'kpi',
    name: 'KPI',
    width: 105,
  },
  {
    renderView: (props) => (props.row.kind === 'MEDIA' ? renderValue(props) : null),
    renderEdit: (props) => <Edit {...props} />,
    displayCellContent: true,
  },
);
