import { Dispatch, Key, ReactElement, forwardRef, SetStateAction, useMemo, useRef, useImperativeHandle } from 'react';
import cx from 'clsx';
import DataGrid, { DataGridHandle, RenderRowProps } from 'components/DataGrid';
import { grid, gridGroup, gridRow } from './style.css';
import { PlanSelection, PlanState } from '../types';
import { renderRowOrGroup } from './Row';
import {
  PlanFactorsProvider,
  ToggleExpandedProvider,
  useColumns,
  useDragMonitor,
  useGridRows,
  useGridSelection,
  useGridSummaryRow,
} from './hooks';
import type { GridSummaryRow, RowKey, RowOrGroup } from './types';
import { Alert, Box } from '@ff-it/ui';
import { SmartOptionsProivder } from './hooks/useSmartOptions';
import { usePlanController } from '../usePlanController';
import { useCalendar } from './calendar';
import { cellIsValid } from './util';
import { useSetStateRenderedCallback } from 'hooks';

const HEADER_HEIGHT = 38;
const ROW_HEIGHT = 40;
const SUMMARY_HEIGHT = 32;

type GridProps = {
  planState: PlanState;
  planSelection: PlanSelection;
  setPlanSelection: Dispatch<SetStateAction<PlanSelection>>;
};

const rowKeyGetter = (r: RowOrGroup): RowKey => r._key;

export type GridHandle = {
  selectCell: (rowKey: string, columnKey?: string, enableEditor?: boolean, scrollToCell?: boolean) => void;
  // selectCell: (position: Position, enableEditor?: boolean, scrollToCell?: boolean) => void;
  focusInvalid: () => void;
};

function EmptyRowsRenderer(): ReactElement {
  return (
    <Box padding="md" style={{ gridColumn: '1/-1' }}>
      <Alert className="m-0">This plan is empty</Alert>
    </Box>
  );
}

const rowClass = (row: RowOrGroup, _rowIdx: number): string => (row._isGroup ? gridGroup : gridRow);

/**
 * Grid mangles PlanRow[] into flat set of RowOrGroup[]
 */
export const Grid = forwardRef<GridHandle, GridProps>(
  ({ planState, planSelection, setPlanSelection }, ref): ReactElement => {
    const { rows: planRows, date_from, date_to, plan_resolution, plan_reach, factors } = planState;
    const gridRef = useRef<DataGridHandle>(null);

    const allRows = useGridRows(planState);
    const summaryRow = useGridSummaryRow(allRows, planState);

    const [expandedGroups, setExpandedGroups] = useSetStateRenderedCallback(
      (): ReadonlySet<RowKey> => new Set<RowKey>(allRows.filter((r) => r._isGroup).map((r) => r._key)),
    );

    const { strategy, onRowsChange, move } = usePlanController();
    const isDragging = useDragMonitor(gridRef, move);

    const [selectedRows, onSelectedRowsChange] = useGridSelection(planSelection, setPlanSelection, allRows);

    const calendar = useCalendar({ date_from, date_to, plan_resolution });
    const columns = useColumns(planSelection !== null, strategy, plan_reach, calendar);

    const groupDrag = isDragging === 'GROUP';

    const rows = useMemo(
      () =>
        groupDrag
          ? allRows.filter((r) => r._isGroup && r.id !== 0)
          : allRows.filter(
              (r) =>
                // group itself
                r._isGroup ||
                // ingrouped
                r.group_id === null ||
                // expanded
                expandedGroups.has(r._groupKey),
            ),
      [allRows, expandedGroups, groupDrag],
    );

    useImperativeHandle(ref, () => {
      const selectCell = (rowKey: string, columnKey?: string, enableEditor?: boolean, scrollToCell?: boolean): void => {
        const rowIdx = rows.findIndex((r) => r._key === rowKey);
        const idx = columnKey ? columns.findIndex((r) => r.key === columnKey) : 0;

        if (rowIdx === -1) {
          // ? Might not be expaneded
        } else {
          gridRef.current?.selectCell({ rowIdx, idx }, enableEditor, scrollToCell);
        }
      };

      const handle = {
        selectCell,
        focusInvalid: () => {
          // Make sure everything is expanded
          setExpandedGroups(new Set<RowKey>(allRows.filter((r) => r._isGroup).map((r) => r._key)), () => {
            for (let rowIdx = 0; rowIdx < allRows.length; rowIdx++) {
              const row = allRows[rowIdx];
              if (row._isGroup) {
                continue;
              }

              for (let idx = 0; idx < columns.length; idx++) {
                if (!cellIsValid(row, columns[idx].key)) {
                  gridRef.current?.selectCell({ rowIdx, idx });
                  return;
                }
              }
            }
          });
        },
      };
      window.__GRID__ = handle;
      return handle;
    });

    const rowsCount = allRows.length + 2;

    function renderRow(key: Key, props: RenderRowProps<RowOrGroup, GridSummaryRow>): ReactElement {
      const isExpanded = props.row._isGroup ? expandedGroups.has(props.row._key) : null;
      return renderRowOrGroup(key, isExpanded, props);
    }

    return (
      <PlanFactorsProvider value={factors}>
        <SmartOptionsProivder planRows={planRows}>
          <ToggleExpandedProvider value={setExpandedGroups}>
            <DataGrid<RowOrGroup, GridSummaryRow, RowKey>
              ref={gridRef}
              rows={rows}
              aria-rowcount={rowsCount}
              rowHeight={ROW_HEIGHT}
              headerRowHeight={HEADER_HEIGHT}
              summaryRowHeight={SUMMARY_HEIGHT}
              columns={columns}
              selectedRows={selectedRows}
              onSelectedRowsChange={onSelectedRowsChange}
              rowKeyGetter={rowKeyGetter}
              className={cx(grid, isDragging && 'dragging')}
              // just edit on clik if possible
              onCellClick={({ selectCell }, e): void => {
                e.preventGridDefault();
                selectCell(true);
              }}
              onRowsChange={onRowsChange}
              bottomSummaryRows={[summaryRow]}
              renderRow={renderRow}
              noRowsFallback={<EmptyRowsRenderer />}
              rowClass={rowClass}
            />
          </ToggleExpandedProvider>
        </SmartOptionsProivder>
      </PlanFactorsProvider>
    );
  },
);
