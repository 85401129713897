import { useMemo } from 'react';
import {
  HandleColumn,
  SelectColumn,
  PeriodColumn,
  StateColumn,
  SupplierColumn,
  UnitColumn,
  GrossPriceColumn,
  GrossTotalColumn,
  ClientFactorsColumn,
  ClientPriceColumn,
  SmartColumn,
  ClientTotalColumn,
  ProviderFactorsColumn,
  ProviderPriceColumn,
  ProviderTotalColumn,
  RevenueMarginColumn,
  ReservationStateColumn,
  ReachImpressionsColumn,
  ReachUniqueUsersColumn,
  ReachCompositionColumn,
  ReachFrequencyColumn,
  ReachColumn,
} from '../columns';
import { type Column } from 'components/DataGrid';
import type { GridSummaryRow, RowOrGroup } from '../types';
import type { PlanStrategy } from 'modules/campaign/block/strategy';
import { Calendar } from '../calendar/types';
import { useCalendarColumn } from '../calendar';
import { createMonthColums } from '../delivery';
import { SumQuantityColumn } from '../columns/SumQuantityColumn';

export function useColumns(
  enableSelection: boolean,
  strategy: PlanStrategy,
  enableReach: boolean,
  calendar: Calendar,
): Column<RowOrGroup, GridSummaryRow>[] {
  const positionsColumn = useCalendarColumn(calendar);

  // biome-ignore lint/correctness/useExhaustiveDependencies: should be fine
  return useMemo(() => {
    return [
      ...(enableSelection ? [SelectColumn] : []),
      HandleColumn,
      ReservationStateColumn,
      StateColumn,
      SupplierColumn,
      PeriodColumn,
      ...strategy.columns,
      // FIXME: to strategy
      ...(strategy.KEY === 'RTB' && enableReach
        ? [ReachImpressionsColumn, ReachUniqueUsersColumn, ReachCompositionColumn, ReachFrequencyColumn, ReachColumn]
        : []),
      UnitColumn,
      GrossPriceColumn,
      SumQuantityColumn,
      GrossTotalColumn,
      ClientFactorsColumn,
      ClientPriceColumn,
      SmartColumn,
      ClientTotalColumn,
      ProviderFactorsColumn,
      ProviderPriceColumn,
      ProviderTotalColumn,
      RevenueMarginColumn,
      positionsColumn,
      ...createMonthColums(calendar.interval),
    ];
  }, [strategy, enableSelection, positionsColumn, enableReach]);
}
